import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { getClass } from "../src/helpers/formatHelpers";

const HeaderSearch = ({ defaultValue, onPress }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { category } = router.query;
  const [keywordSearch, setKeywordSearch] = useState(defaultValue || "");

  return (
    <div className={`sm:block hidden ${getClass("header", true)}`}>
      <div className="wrapper mx-auto py-3">
        <form
          action={
            onPress
              ? ""
              : `/explore/${
                  category ||
                  process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",")[0]
                    ?.toLowerCase()
                    ?.replace("venue cocktail", "bars")
                    ?.replace("venue beachful", "beach-clubs")
                    ?.replace("prefab company", "companies")
                    ?.replace("prefab home", "homes")
                }`
          }>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <div className="mr-6" style={{ height: 40 }}>
                <Link href="/">
                  <a>
                    <Image
                      src={`/images/${
                        process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                        process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                          ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                              " ",
                              ""
                            )?.toLowerCase() + "/"
                          : ""
                      }home.svg`}
                      width={40}
                      height={40}
                      alt={`${process.env.NEXT_PUBLIC_APP_NAME} home`}
                    />
                  </a>
                </Link>
              </div>
              <div
                className={`${getClass(
                  "bg-input",
                  true
                )} rounded-full flex flex-row items-center justify-between pl-8 h-10 w-480px`}>
                <input
                  name="search"
                  type="search"
                  className={`${getClass(
                    "bg-input",
                    true
                  )} font-regular text-base ${getClass(
                    "text-white",
                    true
                  )} ${getClass("placeholder-input")} outline-0 w-335px`}
                  aria-label={`Search ${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                    ","
                  )
                    .join(" | ")
                    ?.replace("Festivals", "Festival")
                    ?.replace("Artists", "Artist")
                    ?.replace("Venues", "Venue")
                    ?.replace("Rooftops", "Rooftop")
                    ?.replace("Spas", "Spa")
                    ?.replace("Venue Cocktail", "Bar")
                    ?.replace("Venue Beachful", "Beach Club")
                    ?.replace("Prefab Company", "Company")
                    ?.replace("Prefab Home", "Home")
                    ?.replace("Parks", "Park")
                    ?.replace("Casinos", "Casino")
                    ?.replace("Castles", "Castle")
                    ?.replace("Resorts", "Resort")
                    ?.replace("Aparthotels", "Aparthotel")}`}
                  placeholder={process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                    ","
                  )
                    .join(" | ")
                    ?.replace("Festivals", "Festival")
                    ?.replace("Artists", "Artist")
                    ?.replace("Venues", "Venue")
                    ?.replace("Rooftops", "Rooftop")
                    ?.replace("Spas", "Spa")
                    ?.replace("Venue Cocktail", "Bar")
                    ?.replace("Venue Beachful", "Beach Club")
                    ?.replace("Prefab Company", "Company")
                    ?.replace("Prefab Home", "Home")
                    ?.replace("Parks", "Park")
                    ?.replace("Casinos", "Casino")
                    ?.replace("Castles", "Castle")
                    ?.replace("Resorts", "Resort")
                    ?.replace("Aparthotels", "Aparthotel")}
                  value={keywordSearch}
                  onChange={(e) => setKeywordSearch(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? onPress
                        ? onPress(keywordSearch)
                        : router.push(
                            `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                              ","
                            )[0]
                              ?.toLowerCase()
                              ?.replace("venue cocktail", "bars")
                              ?.replace("venue beachful", "beach-clubs")
                              ?.replace("prefab company", "companies")
                              ?.replace(
                                "prefab home",
                                "homes"
                              )}?search=${keywordSearch}`
                          )
                      : null
                  }
                />
                <button
                  type="submit"
                  className={`${getClass(
                    "btn-gradient"
                  )} flex justify-center items-center h-10 w-80px -mr-1 rounded-full cursor-pointer`}
                  onClick={() =>
                    onPress
                      ? onPress(keywordSearch)
                      : router.push(
                          `/explore/${
                            category ||
                            process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                              ","
                            )[0]?.toLowerCase()
                          }?search=${keywordSearch}`
                        )
                  }>
                  <Image
                    src="/icons/ic_search.svg"
                    width={20}
                    height={20}
                    alt="search"
                  />
                </button>
              </div>
            </div>
            {/* {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Festivals" || i === "Artists"
            ) ? (
              <div className="flex flex-row items-center">
                <Link href="/charts/festivals">
                  <a className="bg-spliterLine flex justify-center items-center w-150px h-40px rounded-full uppercase cursor-pointer mr-5">
                    <div className="text-white text-base font-bold">Charts</div>
                  </a>
                </Link>
                <Link href="/playlist">
                  <a
                    className={`${getClass(
                      "btn-gradient"
                    )} flex justify-center items-center w-150px h-40px rounded-full uppercase cursor-pointer mr-5`}>
                    <div className="text-white text-base font-bold">
                      Playlist
                    </div>
                  </a>
                </Link>
                <Link href="/guides">
                  <a
                    className={`${getClass(
                      "btn-gradient"
                    )} flex justify-center items-center w-150px h-40px rounded-full uppercase cursor-pointer`}>
                    <div className="text-white text-base font-bold">Guides</div>
                  </a>
                </Link>
              </div>
            ) : (
            )} */}
            <div className="flex flex-row items-center">
              <Link href="/guides">
                <a
                  className={`${getClass(
                    "btn-gradient"
                  )} flex justify-center items-center w-150px h-40px rounded-full uppercase cursor-pointer`}>
                  <div className="text-white text-base font-bold">Guides</div>
                </a>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HeaderSearch;
