import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NextSeo } from "next-seo";

import Header from "../Header";
import Footer from "../Footer";
import SideMenu from "../SideMenu";
import { CardList } from "../../src/components/molecules";
import { NavigationBar } from "../../src/components/atoms";
import { useRouter } from "next/router";
import { Constants, Values } from "../../src/config";
import slugify from "slugify";
import { generateURLSlug } from "../../helpers";
import LastUpdate from "../LastUpdate";
import Head from "next/head";
import moment from "moment";
import { getClass } from "../../src/helpers/formatHelpers";

const ZooAnimalList = (props) => {
  const { t } = useTranslation();
  const { itemData, listData, routes } = props;
  const { id, slug, category, tabType } = routes;

  const router = useRouter();

  const [sideMenuShow, setSideMenuShow] = useState(false);
  const [sideMenuHeaderShow, setSideMenuHeaderShow] = useState(false);
  const [isMobileVersion, setIsMobileVersion] = useState(false);

  const metaTitle = `${itemData?.nickname} Animals`;
  const metaDescription = `Discover all the ${itemData?.nickname} animals`;
  const updatedAt = moment().startOf("week").add("2", "days").toISOString();
  const canonicalURL = `https://${
    process.env.NEXT_PUBLIC_WWW_BASE_URL
  }${generateURLSlug(id, slug, null, category, tabType)}`;

  useEffect(() => {
    setIsMobileVersion(Values.deviceWidth < 640);
  }, [Values.deviceWidth]);

  return (
    <div>
      <Head>
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": canonicalURL,
              url: canonicalURL,
              name: metaTitle,
              dateModified: updatedAt,
            }),
          }}
        />
      </Head>
      <NextSeo
        title={`${metaTitle} - ${process.env.NEXT_PUBLIC_APP_NAME}`}
        description={metaDescription}
        canonical={canonicalURL}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: canonicalURL,
          description: metaDescription,
          images: [
            {
              url: `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}${process.env.NEXT_PUBLIC_BANNER_IMAGE}`,
              width: 1200,
              height: 630,
              alt: `${process.env.NEXT_PUBLIC_APP_NAME}${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
              type: "image/png",
            },
          ],
          siteName: `${process.env.NEXT_PUBLIC_APP_NAME}${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
        }}
      />
      <Header setSideMenuShow={setSideMenuShow} />

      <SideMenu
        sideMenuShow={sideMenuShow}
        setSideMenuShow={setSideMenuShow}
        sideMenuHeaderShow={sideMenuHeaderShow}
        setSideMenuHeaderShow={setSideMenuHeaderShow}
      />

      <div className={`${getClass("background", true)}`}>
        <div
          className={`mx-auto relative ${
            isMobileVersion ? "" : "wrapper pt-[30px]"
          } pb-[130px]`}>
          <div className="max-w-1160px min-h-screen flex-1 mx-auto">
            <NavigationBar
              title={metaTitle}
              customStyle={{ marginTop: 0 }}
              isMobileVersion={isMobileVersion}
              onBackPressed={() =>
                router.push(generateURLSlug(id, slug, null, category, tabType))
              }
            />
            <div className="flex flex-row justify-center items-center">
              <div
                className={`flex flex-col justify-center items-center border ${getClass(
                  "border-black",
                  true
                )} px-[10px] py-[5px] rounded-full ${getClass(
                  "text-white",
                  true
                )} font-regular sm:text-base text-xs text-center mb-[30px]`}>
                <div>
                  <span className={`${getClass("text-gray2")}`}>
                    {listData?.length || 0}
                  </span>
                  &nbsp;Animals
                </div>
              </div>
            </div>
            <div>
              <CardList
                isChartsMode={false}
                isGuides={true}
                horizontal={false}
                gridView={false}
                items={listData}
                category={Constants.categoryType.CATEGORY_ANIMAL}
                style={{ marginTop: 16 }}
                // onItemAction={({ type, item }) => onItemAction({ type, item })}
                isLoadingNextPage={false}
                isLoading={false}
                isExploreSearch
                isMobileVersion={isMobileVersion}
                gapHeight={0}
              />
            </div>
            {listData.length > 0 && (
              <LastUpdate
                isMobileVersion={isMobileVersion}
                updatedAt={updatedAt}
                isGuide
              />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ZooAnimalList;
