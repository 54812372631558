import { useRouter } from "next/router";
import ProfileDetailLayout from "../../../../../components_web/layouts/ProfileDetailLayout";
import { Constants, Values } from "../../../../../src/config";
import ProfileScreen from "../../../../../src/modules/profile";
import { ArtistDao, EventDao, VenueDao } from "../../../../../src/DAO";
import { useEffect, useState } from "react";
import { generateURLSlug } from "../../../../../helpers";
import CompanyHomeList from "../../../../../components_web/guides/CompanyHomeList";
import OtherHomeList from "../../../../../components_web/guides/OtherHomeList";
import ZooAnimalList from "../../../../../components_web/guides/ZooAnimalList";
import FestivalLineupList from "../../../../../components_web/guides/FestivalLineupList";
import ArtistFestivalList from "../../../../../components_web/guides/ArtistFestivalList";

export async function getServerSideProps(context) {
  try {
    const { params, res } = context;
    res.setHeader(
      "Cache-Control",
      "public, s-maxage=10, stale-while-revalidate=59"
    );

    const dataCategoryProps =
      params.category === "artists" &&
      process.env.NEXT_PUBLIC_APP_NAME == "Festyful"
        ? Constants.categoryType.CATEGORY_ARTIST
        : params.category === "festivals" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Festyful"
        ? Constants.categoryType.CATEGORY_FESTIVAL
        : params.category === "venues" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Vybeful"
        ? Constants.categoryType.CATEGORY_VENUE
        : params.category === "events" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Vybeful"
        ? Constants.categoryType.CATEGORY_EVENT
        : params.category === "rooftops" &&
          process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub"
        ? Constants.categoryType.CATEGORY_ROOFTOP
        : params.category === "spas" &&
          process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
        ? Constants.categoryType.CATEGORY_SPA
        : params.category === "beach-clubs" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
        ? Constants.categoryType.CATEGORY_VENUE_BEACHFUL
        : params.category === "companies" &&
          process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
        ? Constants.categoryType.CATEGORY_PREFAB_COMPANY
        : params.category === "homes" &&
          process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
        ? Constants.categoryType.CATEGORY_PREFAB_HOMES
        : params.category === "zoos" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
        ? Constants.categoryType.CATEGORY_ZOO
        : params.category === "animals" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
        ? Constants.categoryType.CATEGORY_ANIMAL
        : params.category === "parks" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
        ? Constants.categoryType.CATEGORY_PARKFUL
        : params.category === "casinos" &&
          process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino"
        ? Constants.categoryType.CATEGORY_CASINO
        : params.category === "castles" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
        ? Constants.categoryType.CATEGORY_CASTLE
        : params.category === "resorts" &&
          process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club"
        ? Constants.categoryType.CATEGORY_RESORT
        : params.category === "aparthotels" &&
          process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub"
        ? Constants.categoryType.CATEGORY_APARTHOTEL
        : params.category === "bars" &&
          process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl"
        ? Constants.categoryType.CATEGORY_VENUE_COCKTAIL
        : null;

    if (
      params.category == "spa" &&
      process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
    ) {
      return {
        redirect: {
          permanent: true,
          destination: `/spas/${params.slug}/${params.id}/${params.tabType}`,
        },
      };
    } else if (
      params.category == "venues-beachful" &&
      process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
    ) {
      return {
        redirect: {
          permanent: true,
          destination: `/beach-clubs/${params.slug}/${params.id}/${params.tabType}`,
        },
      };
    }

    if (!dataCategoryProps) {
      return {
        redirect: {
          permanent: false,
          destination: "/",
        },
      };
    }

    const item = await new Promise((resolve, reject) => {
      if (params.category === "artists") {
        ArtistDao.findOne(params.id)
          .then((result) => resolve(result))
          .catch((err) => reject(err));
      } else if (
        params.category === "festivals" ||
        params.category === "events"
      ) {
        EventDao.findOne(params.id)
          .then((result) => resolve(result))
          .catch((err) => reject(err));
      } else {
        VenueDao.findOne(params.id)
          .then((result) => resolve(result))
          .catch((err) => reject(err));
      }
    });
    const lineupArtists = await new Promise((resolve, reject) => {
      if (params.category === "festivals" || params.category === "events") {
        ArtistDao.findWeb({
          pageSize: 10,
          sortBy: Constants.sortByAction.SORT_BY_POPULARITY,
          eventId: params.id,
        })
          .then((result) => resolve(result))
          .catch((err) => reject(err));
      } else {
        resolve([]);
      }
    });
    const lineup = lineupArtists?.data || [];
    const artistEvents = await new Promise((resolve, reject) => {
      if (params.category === "artists") {
        const query = {
          pageIndex: 0,
          pageSize: 24,
          artistProfileId: item?.profileId,
          isAnytime: true,
        };
        EventDao.findWeb(query)
          .then((result) => resolve(result.data))
          .catch((err) => reject(err));
      } else {
        resolve([]);
      }
    });
    const venueEvents = await new Promise((resolve, reject) => {
      if (params.category === "venues") {
        const query = {
          pageIndex: 0,
          pageSize: 24,
          dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
          venueId: params.id,
        };
        EventDao.findWeb(query)
          .then((result) => resolve(result.data))
          .catch((err) => reject(err));
      } else {
        resolve([]);
      }
    });

    return { props: { item, artistEvents, venueEvents, lineup } };
  } catch (err) {
    console.error("err", err);
    return {
      notFound: true,
    };
  }
}

const ProfilePage = (props) => {
  const router = useRouter();
  const { asPath } = useRouter();
  const {
    id,
    slug,
    category,
    tabType: tabTypeQuery,
    subTabType,
  } = router.query;
  const tabType = asPath.split("#")[1] || "about";
  const [isMobileVersion, setIsMobileVersion] = useState(false);

  const params = {
    category:
      category === "artists"
        ? Constants.categoryType.CATEGORY_ARTIST
        : category === "festivals"
        ? Constants.categoryType.CATEGORY_FESTIVAL
        : category === "events"
        ? Constants.categoryType.CATEGORY_EVENT
        : category === "rooftops"
        ? Constants.categoryType.CATEGORY_ROOFTOP
        : category === "spas"
        ? Constants.categoryType.CATEGORY_SPA
        : category === "beach-clubs"
        ? Constants.categoryType.CATEGORY_VENUE_BEACHFUL
        : category === "companies"
        ? Constants.categoryType.CATEGORY_PREFAB_COMPANY
        : category === "homes"
        ? Constants.categoryType.CATEGORY_PREFAB_HOMES
        : category === "zoos"
        ? Constants.categoryType.CATEGORY_ZOO
        : category === "animals"
        ? Constants.categoryType.CATEGORY_ANIMAL
        : category === "parks"
        ? Constants.categoryType.CATEGORY_PARKFUL
        : category === "casinos"
        ? Constants.categoryType.CATEGORY_CASINO
        : category === "castles"
        ? Constants.categoryType.CATEGORY_CASTLE
        : category === "resorts"
        ? Constants.categoryType.CATEGORY_RESORT
        : category === "aparthotels"
        ? Constants.categoryType.CATEGORY_APARTHOTEL
        : category === "bars"
        ? Constants.categoryType.CATEGORY_VENUE_COCKTAIL
        : Constants.categoryType.CATEGORY_VENUE,
    id,
    tabType: tabType,
    subTabType: subTabType,
    item: props.item,
    lineup: props.lineup,
    artistEvents: props.artistEvents,
    venueEvents: props.venueEvents,
  };

  useEffect(() => {
    if (
      tabTypeQuery !== "about" &&
      tabTypeQuery !== "homes" &&
      // tabTypeQuery !== "other-homes" &&
      tabTypeQuery !== "lineup" &&
      tabTypeQuery !== "animals" &&
      tabTypeQuery !== "festivals"
    ) {
      router.replace(
        category === "artists"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.displayName,
              null,
              Constants.categoryType.CATEGORY_ARTIST,
              "about#" + tabTypeQuery
            )}`
          : category === "festivals"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_FESTIVAL,
              "about#" + tabTypeQuery
            )}`
          : category === "events"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_EVENT,
              "about#" + tabTypeQuery
            )}`
          : category === "rooftops"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_ROOFTOP,
              "about#" + tabTypeQuery
            )}`
          : category === "spas"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_SPA,
              "about#" + tabTypeQuery
            )}`
          : category === "beach-clubs"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_VENUE_BEACHFUL,
              "about#" + tabTypeQuery
            )}`
          : category === "companies"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_PREFAB_COMPANY,
              "about#" + tabTypeQuery
            )}`
          : category === "homes"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_PREFAB_HOMES,
              "about#" + tabTypeQuery
            )}`
          : category === "zoos"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_ZOO,
              "about#" + tabTypeQuery
            )}`
          : category === "animals"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_ANIMAL,
              "about#" + tabTypeQuery
            )}`
          : category === "parks"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_PARKFUL,
              "about#" + tabTypeQuery
            )}`
          : category === "casinos"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_CASINO,
              "about#" + tabTypeQuery
            )}`
          : category === "castles"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_CASTLE,
              "about#" + tabTypeQuery
            )}`
          : category === "resorts"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_RESORT,
              "about#" + tabTypeQuery
            )}`
          : category === "aparthotels"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_APARTHOTEL,
              "about#" + tabTypeQuery
            )}`
          : category === "bars"
          ? `${generateURLSlug(
              props?.item?.id,
              props?.item?.title,
              props?.item?.startDate,
              Constants.categoryType.CATEGORY_VENUE_COCKTAIL,
              "about#" + tabTypeQuery
            )}`
          : `${generateURLSlug(
              props?.item?.id,
              props?.item?.nickname,
              null,
              Constants.categoryType.CATEGORY_VENUE,
              "about#" + tabTypeQuery
            )}`,
        undefined,
        { shallow: true }
      );
    }
  }, []);

  useEffect(() => {
    setIsMobileVersion(Values.deviceWidth < 640);
  }, [Values.deviceWidth]);

  if (tabTypeQuery === "homes") {
    return (
      <CompanyHomeList
        itemData={props.item}
        listData={props.item?.companyHomes}
        routes={{
          id,
          slug,
          category: Constants.categoryType.CATEGORY_PREFAB_COMPANY,
          tabType,
        }}
      />
    );
    // } else if (tabTypeQuery === "other-homes") {
    //   return (
    //     <OtherHomeList
    //       itemData={props.item}
    //       listData={props.item?.otherHomes}
    //       routes={{
    //         id,
    //         slug,
    //         category: Constants.categoryType.CATEGORY_PREFAB_HOMES,
    //         tabType,
    //       }}
    //     />
    //   );
  } else if (tabTypeQuery === "animals") {
    return (
      <ZooAnimalList
        itemData={props.item}
        listData={props.item?.companyHomes}
        routes={{
          id,
          slug,
          category: Constants.categoryType.CATEGORY_ZOO,
          tabType,
        }}
      />
    );
  } else if (tabTypeQuery === "lineup") {
    return (
      <FestivalLineupList
        itemData={props.item}
        routes={{
          id,
          slug,
          category: Constants.categoryType.CATEGORY_FESTIVAL,
          tabType,
        }}
      />
    );
  } else if (tabTypeQuery === "festivals") {
    return (
      <ArtistFestivalList
        itemData={props.item}
        routes={{
          id,
          slug,
          category: Constants.categoryType.CATEGORY_ARTIST,
          tabType,
        }}
      />
    );
  } else {
    return (
      <ProfileDetailLayout>
        <ProfileScreen {...params} isMobileVersion={isMobileVersion} />
      </ProfileDetailLayout>
    );
  }
};

export default ProfilePage;
