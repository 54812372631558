import React from "react";

import { CImage } from "../src/components/atoms";
import { Colors, Constants, Fonts, Images } from "../src/config";
import moment from "moment";
import { getClass } from "../src/helpers/formatHelpers";

const LastUpdate = ({ isMobileVersion, updatedAt, isGuide }) => {
  return (
    <div
      style={{
        marginTop: 15,
        display: "flex",
        flexDirection: "row",
        alignSelf: "stretch",
        alignItems: "center",
        marginLeft: isGuide ? 15 : 0,
      }}>
      <div
        style={{
          width: isMobileVersion ? 20 : 22,
          height: isMobileVersion ? 20 : 22,
          backgroundColor: getClass("bg-icon-gray1"),
          borderRadius: 100,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginLeft: 2,
        }}>
        <CImage
          source={Images.iconCalendarWhite}
          layout="fixed"
          width={isMobileVersion ? 10 : 12}
          height={isMobileVersion ? 10 : 12}
        />
      </div>
      <div
        style={{
          fontSize: isMobileVersion ? 9 : 12,
          fontFamily: Fonts.primaryRegular,
          color: getClass("text-darkGray"),
          marginLeft: "10px",
          textAlign: "left",
        }}>
        <time dateTime={updatedAt} pubDate>
          Last updated on {moment(updatedAt).format("DD/MM/YYYY")}
        </time>
      </div>
    </div>
  );
};

export default LastUpdate;
