import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Image, Text } from "react-native-web";
import { CImage } from "../../../../components/atoms";
import { Colors, Constants, Fonts, Images } from "../../../../config";

const EventStatusView = (props) => {
  const { eventStatus, isMobileVersion } = props;
  const { t } = useTranslation();
  const isLive = eventStatus == Constants.festivalStatus.FE_STAT_UPCOMMING_LIVE;
  const getStatusString = (_status) => {
    const statusStringList = {};
    statusStringList[Constants.festivalStatus.FE_STAT_UPCOMMING_TO_BE_UPDATED] =
      t("key_fest_status_upcomming_to_be_updated");
    statusStringList[Constants.festivalStatus.FE_STAT_UPCOMMING_LIVE] = t(
      "key_fest_status_upcomming_live"
    );
    statusStringList[Constants.festivalStatus.FE_STAT_UPCOMMING_CANCELED] = t(
      "key_fest_status_upcomming_canceled"
    );
    statusStringList[
      Constants.festivalStatus.FE_STAT_RESCHEDULE_TO_BE_UPDATED
    ] = t("key_fest_status_reschedule_to_be_updated");
    statusStringList[Constants.festivalStatus.FE_STAT_PAST] = t(
      "key_fest_status_past"
    );
    return statusStringList[_status] || "";
  };

  const renderLiveContent = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
        <CImage
          source={Images.iconFestivalLiveStatus}
          style={styles.icon}
          objectFit="contain"
          layout="fixed"
          width={isMobileVersion ? 18 : 30}
          height={isMobileVersion ? 18 : 30}
        />
        <Text
          style={[
            styles.title,
            { color: Colors.white, marginLeft: 8 },
            isMobileVersion ? { fontSize: 14, lineHeight: 14 } : {},
          ]}>
          {t("key_fest_status_upcomming_live")}
        </Text>
      </View>
    );
  };
  const renderContent = () => {
    if (isLive) {
      return renderLiveContent();
    }
    const statusString = getStatusString(eventStatus);
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Text
          style={[
            styles.title,
            isMobileVersion ? { fontSize: 14, lineHeight: 14 } : {},
          ]}>
          {statusString}
        </Text>
      </View>
    );
  };
  return (
    <View
      style={[
        styles.container,
        isMobileVersion ? { height: 34 } : {},
        props.style,
      ]}>
      {renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    backgroundColor: Colors.grayBackground,
    height: 50,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 18,
    height: 18,
  },
  title: {
    fontSize: 20,
    fontFamily: Fonts.primaryBold,
    fontWeight: "900",
    color: Colors.grayText2,
  },
});

export default EventStatusView;
