import React, { useEffect, useCallback, useState, useRef } from "react";
import { Colors, Constants, Fonts, Images } from "../../../../config";
import { StyleSheet, View, Text } from "react-native-web";
import { useTranslation } from "react-i18next";
import { getPeriod } from "../../../../helpers";

const ItemView = (props) => {
  const { isMobileVersion } = props;
  return (
    <View style={styles.itemContainer}>
      <Text
        style={[
          styles.itemTitle,
          isMobileVersion
            ? {
                fontSize: 10,
                lineHeight: 12,
              }
            : {},
        ]}>
        {props.title}
      </Text>
      <Text
        style={[
          styles.itemDescription,
          isMobileVersion
            ? {
                fontSize: 8,
                lineHeight: 8,
              }
            : {},
        ]}>
        {props.description}
      </Text>
    </View>
  );
};

const DurationView = (props) => {
  const { t } = useTranslation();
  const { days, hours, minutes, seconds } = getPeriod(props.duration);
  const { isMobileVersion } = props;
  return (
    <View
      style={[
        styles.container,
        isMobileVersion ? { height: 36 } : {},
        props.style,
      ]}>
      <ItemView
        title={days}
        isMobileVersion={isMobileVersion}
        description={t("key_days")}
      />
      <ItemView
        title={hours}
        isMobileVersion={isMobileVersion}
        description={t("key_hrs")}
      />
      <ItemView
        title={minutes}
        isMobileVersion={isMobileVersion}
        description={t("key_min")}
      />
      <ItemView
        title={seconds}
        isMobileVersion={isMobileVersion}
        description={t("key_sec")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignSelf: "stretch",
    backgroundColor: Colors.grayBackground,
    height: 50,
    borderRadius: 25,
    paddingHorizontal: 5,
  },
  itemContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  itemTitle: {
    fontSize: 16,
    lineHeight: 21,
    color: Colors.grayText2,
    fontFamily: Fonts.primaryBold,
  },
  itemDescription: {
    lineHeight: 12,
    fontSize: 12,
    fontFamily: Fonts.primaryBold,
    color: Colors.white,
    marginTop: 2,
  },
});

export default DurationView;
