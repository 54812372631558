import { getClass } from "../../src/helpers/formatHelpers";
import HeaderSearch from "../HeaderSearch";

const layoutStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  minHeight: "100vh",
};

const contentStyle = {
  // flex: 1,
  // display: "flex",
  flexDirection: "column",
  marginTop: 0,
};

const ProfileDetailLayout = (props) => {
  const { hideBottomMargin } = props;

  return (
    <div className={`${getClass("background", true)} relative`}>
      <div className="" style={layoutStyle}>
        <HeaderSearch />
        <div className="profile-content-view" style={contentStyle}>
          {props.children}
        </div>
        {!hideBottomMargin && (
          <div
            style={{
              height: 64,
              alignSelf: "stretch",
              backgroundColor: getClass("background"),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileDetailLayout;
