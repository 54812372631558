import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  SectionList,
} from "react-native-web";
import Link from "next/link";

import SectionView from "./components/SectionView";
import CardItem from "./components/CardItem";
import { generateURLSlug } from "../../../../helpers";
import { Colors, Fonts } from "../../../config";

const CardSectionList = (props) => {
  const { items, isMobileVersion } = props;
  const [showAll, setShowAll] = useState(false);
  const onItemAction = ({ type, item }) => {
    if (props.onItemAction) {
      props.onItemAction({ type, item });
    }
  };
  const onLoadMore = () => {
    if (props.onLoadMore) {
      props.onLoadMore();
    }
  };
  const onRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh();
    }
  };
  const getItemLinkUrl = (item, category) => {
    if (props.getItemLinkUrl) {
      return props.getItemLinkUrl(item);
    }
    return generateURLSlug(
      item.id,
      item.title || item.displayName || item.nickname,
      item.startDate,
      category
    );
  };
  const renderItem = ({ item, index }) => {
    if (!item) return null;
    const url = getItemLinkUrl(item, props.category);
    return (
      <Link prefetch={false} href={url}>
        <a>
          <CardItem
            item={item}
            category={props.category}
            onItemAction={onItemAction}
            isMobileVersion={isMobileVersion}
          />
        </a>
      </Link>
    );
  };
  const renderSectionItem = ({ section }) => {
    return <SectionView section={section} isMobileVersion={isMobileVersion} />;
  };
  return (
    <View style={[styles.container, props.style]}>
      <SectionList
        sections={items.map((i) => ({
          ...i,
          data: i.data.filter((y, index) => (showAll ? y : index < 7)),
        }))}
        // sections={items}
        renderItem={renderItem}
        renderSectionHeader={renderSectionItem}
        refreshing={props.isLoading || false}
        keyExtractor={(item, index) => index.toString()}
        onRefresh={onRefresh}
        onEndReachedThreshold={0.4}
        onEndReached={onLoadMore}
        isMobileVersion={isMobileVersion}
      />
      {items.map((i) => i.data).flat().length > 7 && !showAll && (
        <TouchableOpacity
          style={
            isMobileVersion
              ? styles.mobileLoadMoreButtonContainer
              : styles.loadMoreButtonContainer
          }
          onPress={() => setShowAll(true)}>
          <Text
            style={[
              styles.buttonText,
              isMobileVersion && { fontSize: 12, lineHeight: 12 },
            ]}>
            VIEW MORE
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loadMoreButtonContainer: {
    width: 180,
    height: 40,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    alignSelf: "center",
    marginTop: 18,
  },
  mobileLoadMoreButtonContainer: {
    width: 120,
    height: 32,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    alignSelf: "center",
    marginTop: 18,
  },
  buttonText: {
    color: Colors.white,
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    textAlign: "center",
  },
});

export default CardSectionList;
