
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[category]/[slug]/[id]/[tabType]",
      function () {
        return require("private-next-pages/[category]/[slug]/[id]/[tabType]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[category]/[slug]/[id]/[tabType]"])
      });
    }
  